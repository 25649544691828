
import { defineComponent } from 'vue';
import { useGameStore } from '@/store';

export default defineComponent({
    name: 'AppsHome',
    setup() {
        const store = useGameStore();

        return {
            store
        }
    },
    data: () => {
        return {
            apps: [
                {
                    path: '/apps/messenger',
                    name: 'Messages',
                    icon: 'chat',
                    color: '#075E54'
                },
                {
                    path: '/apps/photos',
                    name: 'Fotos',
                    icon: 'photo',
                    color: '#4d62d6'
                },
                {
                    path: '/apps/routes',
                    name: 'Fahrplan',
                    icon: 'list',
                    color: 'darkred'
                }
            ],
        }
    },
    methods: {
        open: function(app: { path: string }) {
            const isEnabled = this.store.$state.round?.enabledApps?.findIndex(((s) => s == app.path.replace('/apps/', '')));
            if ( isEnabled != undefined && isEnabled >= 0) {
                this.$router.push(app.path);
            } else {
                alert('Diese App brauchst du gerade nicht.')
            }
        }
    }
})
