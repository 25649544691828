import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: "header",
    style: _normalizeStyle('background-color: ' + _ctx.backgroundColor)
  }, [
    _createVNode(_component_router_link, {
      to: _ctx.backLink ?? '/apps',
      class: "material-icons back"
    }, {
      default: _withCtx(() => [
        _createTextVNode("arrow_back")
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
  ], 4))
}