
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ActionButton',
    props: {
        title: String,
        working: Boolean
    },
})
