
import { defineComponent } from 'vue';
import { useGameStore } from '@/store';
import ActionButton from '@/components/ui/ActionButton.vue';

export default defineComponent({
    name: 'JoinView',
    components: {
        ActionButton
    },
    data: () => {
        return {
            sent: false,
            pin: null
        }
    },
    setup: () => {
        const store = useGameStore();

        return {
            store
        };
    },
    methods: {
        start: async function() {
            this.sent = true;

            // Save pin and session id
            localStorage.setItem('session.id', this.$route.params.code as string);
            localStorage.setItem('session.pin', `${this.pin}`);

            // Try to start a game
            const result = await fetch(process.env.VUE_APP_BACKEND_URL + "/game/start", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    session: this.$route.params.code,
                    pin: this.pin
                }),
                credentials: "include"
            });

            if (result.status == 200) {
                // Fetch the game
                await this.store.refresh();
            
                this.$router.push('/');
            }
        }
    }
})
