import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-acb4ab4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app" }
const _hoisted_2 = { class: "chats" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "profile" }
const _hoisted_5 = { class: "info" }
const _hoisted_6 = { class: "name" }
const _hoisted_7 = { class: "last-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppHeader, {
      title: "Meine Chats",
      backgroundColor: "#075E54"
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.$state.round.messenger.chats, (chat) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "chat",
          key: chat.sender,
          onClick: ($event: any) => (_ctx.$router.push('/apps/messenger/chat/' + chat.senderId))
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(chat.profile), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(chat.sender), 1),
            _createElementVNode("span", _hoisted_7, _toDisplayString(chat.messages[chat.messages.length - 1].message), 1)
          ])
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}