
import AppHeader from '@/components/ui/apps/appHeader.vue';
import { defineComponent } from 'vue';
import { useGameStore } from '@/store/index';


export default defineComponent({
    name: 'RoutesHome',
    components: {
        AppHeader
    },
    setup: () => {
        const store = useGameStore();

        return {
            store
        };
    }
})
