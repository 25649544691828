
import { defineComponent } from 'vue';
import { useGameStore } from '@/store';

export default defineComponent({
    name: 'gameHeader',
    props: {
        round: String,
        maxRounds: String,
        gameName: String
    },
    computed: {
        getRound() {
            let round = this.store.$state.round?.index
            let maxRounds = this.store.$state.round?.game.rounds

            if (!round || !maxRounds) {
                return '...'
            }

            if (round > maxRounds) {
                return 'Spiel abgeschlossen'
            }

            return round + ' / ' + maxRounds;
        }
    },
    setup() {
        const store = useGameStore();

        return {
            store
        }
    }
})
