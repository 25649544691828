import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22f21dff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "apps" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "material-icons" }
const _hoisted_4 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.apps, (app) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "mapp",
        key: app.path,
        onClick: (s) => _ctx.open(app)
      }, [
        _createElementVNode("div", {
          class: "icon",
          style: _normalizeStyle('background-color: ' + app.color)
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(app.icon), 1)
        ], 4),
        _createElementVNode("span", _hoisted_4, _toDisplayString(app.name), 1)
      ], 8, _hoisted_2))
    }), 128))
  ]))
}