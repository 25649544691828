
import { defineComponent } from 'vue';
import { useGameStore } from './store';
import gameHeader from '@/components/ui/header.vue';
import gameFooter from '@/components/ui/footer.vue';

export default defineComponent({
  name: 'viewHome',
  components: {
    gameHeader,
    gameFooter
  },
  setup() {
    // Initialize the pina store
    const store = useGameStore();

    store.connect();

    return {};
  },
})
