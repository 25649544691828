import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01ee0f96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppHeader, {
      title: "Meine Fotos",
      "background-color": "#4d62d6"
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.$state.round.photos.photos, (photo) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "photo",
          key: photo.id
        }, [
          _createElementVNode("img", {
            src: photo.path
          }, null, 8, _hoisted_3)
        ]))
      }), 128))
    ])
  ]))
}