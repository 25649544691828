import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e4d674a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app" }
const _hoisted_2 = { class: "messages" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppHeader, {
      title: _ctx.chat?.sender ?? 'Unknown',
      backgroundColor: "#075E54",
      backLink: "/apps/messenger"
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat?.messages, (message) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass('message ' + (message.highlight ? 'highlight' : '')),
          key: message.message
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(message.message), 1)
        ], 2))
      }), 128))
    ])
  ]))
}