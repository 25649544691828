import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gameHeader = _resolveComponent("gameHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_gameFooter = _resolveComponent("gameFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_gameHeader, {
      round: "1",
      maxRounds: "20",
      gameName: "Escape Room #1"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_gameFooter)
  ], 64))
}