
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AppHeader',
    props: {
        title: String,
        backgroundColor: String,
        backLink: String
    }
})
