
import { defineComponent } from 'vue';
import { useGameStore } from '@/store';
import AppHeader from '@/components/ui/apps/appHeader.vue';

export default defineComponent({
    name: 'MessengerChat',
    components: {
        AppHeader,
    },
    setup: () => {
        const store = useGameStore();

        return {
            store
        };
    },
    computed: {
        chat() {
            return this.store.getChat(this.$route.params.name as string);
        }
    }
})
