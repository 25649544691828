import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import RoundHome from '../views/game/RoundHome.vue';
import AppsHome from '../views/apps/AppsHome.vue';
import MessengerHome from '../views/apps/messenger/MessengerHome.vue';
import MessengerChat from '../views/apps/messenger/MessengerChat.vue';
import JoinView from '@/views/game/JoinView.vue';
import PhotosHome from '@/views/apps/photos/PhotosHome.vue';
import GamesList from '@/views/GamesList.vue';
import RoutesHome from '@/views/apps/routes/RoutesHome.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'gameHome',
    component: RoundHome
  },
  {
    path: '/apps',
    name: 'appsHome',
    component: AppsHome
  },
  {
    path: '/apps/messenger',
    name: 'messengerHome',
    component: MessengerHome
  },
  {
    path: '/apps/photos',
    name: 'photosHome',
    component: PhotosHome
  },
  {
    path: '/apps/messenger/chat/:name',
    name: 'messengerChat',
    component: MessengerChat
  },
  {
    path: '/game/:code',
    name: 'joinGame',
    component: JoinView
  },
  {
    path: '/games',
    name: 'gamesList',
    component: GamesList
  },
  {
    path: '/apps/routes',
    name: 'routesList',
    component: RoutesHome
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

/*router.beforeEach(async (to, from) => {
  const store = useGameStore();

  if (!store.$state.connected && to.name != "joinGame" && to.name != "gamesList") {
    return { name: 'gamesList' }
  }
})*/

export default router
