import { GameState } from "@/types/state/game.i";
import { defineStore } from "pinia";

export const useGameStore = defineStore('game', {
  state: () => {
    return {
      connected: undefined,
      round: undefined
    } as GameState;
  },
  getters: {
    getChat: (state) => {
      return (id: string) => {
        const chat = state.round?.messenger.chats.filter((c) => c.senderId == id);
        console.log(state.round?.messenger.chats, chat);

        if (chat && chat.length > 0) {
          return chat[0]
        }

        return null;
      }
    },
    getPrimaryImage: (state) => {
      if (state.round?.info.image) {
        return process.env.VUE_APP_BACKEND_URL + `/game/assets/${state.round?.index ?? 1}.jpg`;
      }
    }
  },
  actions: {
    refresh: async function() {
      const result = await fetch(process.env.VUE_APP_BACKEND_URL + "/game/state", { credentials: "include" });

      if (result.status == 200) {
        this.connected = true;
        this.round = await result.json();

        console.log('ROUND', JSON.stringify(this.round));
      } else {
        this.connected = false;

        localStorage.clear();
      }
    },
    connect: async function() {
      if (localStorage.getItem('session.id') && localStorage.getItem('session.pin')) {
        await this.refresh();
      } else {
        this.connected = false;
      }
    }
  }
});