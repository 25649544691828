
import { useGameStore } from '@/store';
import { defineComponent } from 'vue';
import ActionButton from '@/components/ui/ActionButton.vue';

export default defineComponent({
    name: 'RoundHome',
    components: {
        ActionButton
    },
    data() {
        return {
            solution: "",
            sent: false,
            error: null as string | null,
        }
    },
    setup: () => {
        const store = useGameStore();

        return {
            store
        };
    },
    methods: {
        solve: async function() {
            this.sent = true;

            // Make a new request
            const result = await fetch(process.env.VUE_APP_BACKEND_URL + "/game/solve", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    solution: this.solution
                })
            });

            if (result.status == 200) {
                await this.store.refresh();
                this.error = "";
                this.sent = false;
                this.solution = "";
            } else {
                this.sent = false;

                this.error = result.status == 406 ? "Die Lösung ist falsch." : "Es gab einen unbekannten Fehler. Versuche die Seite neuzuladen.";
            }
        }
    }
})
