import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f2b4b28e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app" }
const _hoisted_2 = { class: "routes" }
const _hoisted_3 = { class: "route" }
const _hoisted_4 = { class: "destination" }
const _hoisted_5 = { class: "end" }
const _hoisted_6 = { class: "via" }
const _hoisted_7 = { class: "departure" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppHeader, {
      title: "Fahrpläne",
      "background-color": "darkred"
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.$state.round?.routes, (route) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "route",
          key: route.departure
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(route.route), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(route.destination), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(route.via), 1)
          ]),
          _createElementVNode("span", _hoisted_7, _toDisplayString(route.departure), 1)
        ]))
      }), 128))
    ])
  ]))
}